<template>
    <editor></editor>
</template>
<script>
import Editor from "@/components/Editor"; 
export default {
    name: 'EditContent',
    components: {
    Editor
  },
}
</script>